<template>
  <div class="main-view">
    <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
      <el-form-item label="年份筛选" prop="create_year">
        <el-date-picker
          v-model="table.params.create_year"
          type="year"
          placeholder="选择年"
          format="yyyy年"
          value-format="yyyy">
        </el-date-picker>
        <span
          :class="table.params.create_month == v ? 'monthV acColor' : 'monthV' "
          v-for="v in this.monthObj"
          :key="v"
          @click="selMonth(v)"
        >{{v}}月</span>
      </el-form-item>
    </el-form>
    <div class="tableType">
      <el-radio-group v-model="table.params.status" @change="handleTabs">
        <el-radio-button :label="1">待确认({{statusTotal.to_review_count}})</el-radio-button>
        <el-radio-button :label="3">待定稿({{statusTotal.to_confirm_count}})</el-radio-button>
        <el-radio-button :label="4">待发放({{statusTotal.stay_out_count}})</el-radio-button>
        <el-radio-button :label="5">已发放({{statusTotal.issued_count}})</el-radio-button>
      </el-radio-group>
    </div>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="用工单位" prop="company_id">
          <el-select v-model="table.params.company_id" placeholder="请选择" filterable>
            <el-option
              v-for="item in companyOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年份筛选" prop="year">
          <el-date-picker
            v-model="table.params.year"
            type="year"
            placeholder="选择年"
            format="yyyy年"
            value-format="yyyy">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="月份筛选" prop="month">
          <el-date-picker
            v-model="table.params.month"
            type="month"
            placeholder="选择月"
            format="MM月"
            value-format="MM">
          </el-date-picker>
        </el-form-item>
        <template v-if="this.table.params.status === 5">
          <el-form-item label="发放状态" prop="pay_status">
            <el-select v-model="table.params.pay_status" placeholder="请选择" filterable>
              <el-option
                v-for="item in selPayStatus"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        <el-form-item v-if="this.icId == 0" label="开票单位" prop="invoice_company_id">
          <el-select v-model="table.params.invoice_company_id" placeholder="请选择" clearable filterable @change="getTable">
            <el-option
              v-for="item in invoiceCompanyOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询
          </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <!-- 状态：3 待定稿 4待发放 5 已发放 -->
      <div class="btnAdd" >
        <el-button v-show="this.table.params.status === 1" :size="subUnitSize" type="primary" icon="el-icon-plus" @click="handleAdd">新增工资表</el-button>
        <el-button v-show="this.table.params.status === 4" :size="subUnitSize" type="primary" @click="handleBatch">批量发放</el-button>
      </div>
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="curField"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:year="{row}">
        <span>{{row.year}}-{{row.month}}</span>
      </template>
      <!-- pay_status 发放状态 0 未定稿 1待发放 2 已回款已发放 3 未回款已垫付 -->
      <template v-slot:pay_status="{row}">
        <template v-if="row.pay_status === 0 && row.status === 1">
          <span >待确认</span>
        </template>
        <template v-else-if="row.pay_status === 3">
          <el-button type="text" @click="handleStatus(row)">未回款已垫付</el-button>
        </template>
        <template v-else>
          <p v-for="item in payStatus" :key="item.id">
            <span v-if="item.id === row.pay_status">{{item.name}}</span>
          </p>
        </template>
      </template>
      <template v-slot:gzb="{row}">
         <!-- 只有分公司的待确认状态可以上传工资表，其他状态以及总部都是预览工资表(在职人数为0时禁用预览按钮) -->
        <el-button type="text" @click="handlePreview(row)" :disabled="row.total_employees === 0 ? true : false">预览</el-button>
      </template>
      <template v-slot:invoice_company_id="{row}">
        <span>{{row.invoice.name}}</span>
      </template>
      <template v-slot:action="{row}">
        <!-- 发放状态 0 未定稿 1待发放 2 已回款已发放 3 未回款已垫付 -->
        <template v-if="row.pay_status === 0 && table.params.status > 2">
          <el-button type="text" @click="handleConfirm(0, row)">驳回</el-button>
          <el-button type="text" @click="handleConfirm(1, row)">定稿</el-button>
        </template>
        <template v-if="row.pay_status === 1">
          <el-button type="text" @click="handleRetreat(row)">退回</el-button>
          <el-button type="text" @click="handleUntreadList(row, '1')">无卡</el-button>
          <el-button type="text" @click="handleConfirm(2,row)">发放</el-button>
          <el-button type="text" @click="showDetail(row)">查看</el-button>
        </template>
        <template v-if="row.pay_status === 2 || row.pay_status === 3">
          <el-button type="text" @click="showDetail(row)">查看</el-button>
          <el-button type="text" @click="handleUntreadList(row, '2')">退回</el-button>
        </template>
        <el-button type="text" @click="handleExport(row)">下载</el-button>
      </template>
    </VTable>

    <detail ref="detail"></detail>
    <!-- 驳回，定稿，发放确认 -->
    <confirm ref="confirm" :curType="curType" :curRow="curRow" @refresh="getTable"></confirm>

    <untread-list ref="UntreadList"></untread-list>
    <preview ref="preview"></preview>
    <!-- 资结算-新增工资表 -->
    <add ref="add" @refresh="getTable"></add>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <span>
        <!-- <el-radio v-model="radioStatus" :label="1" border>已回款已发放</el-radio> -->
        <el-checkbox v-model="dialogInfo.radioStatus">已回款已发放</el-checkbox>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="statusCommit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import Detail from './components/Detail.vue'
import Confirm from './components/Confirm.vue'
import Preview from './components/Preview.vue'
import Add from './components/Add.vue'
import VTable from '@/components/VTable';
import {getStorage} from '@/storage'
import { exportData } from '@/util'
import UntreadList from './components/untreadList.vue';
import { mapGetters } from 'vuex'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: '',
  mixins:[mixinTable],
  components: {
    Detail,
    Confirm,
    Preview,
    VTable,
    UntreadList,
    Add
  },
  computed: {
    ...mapGetters({
      companyOpt: 'companyList', // 获取企业列表
      invoiceCompanyOpt: 'invoiceCompany', // 获取开票单位列表
    })
  },
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID", width:"80"},
        { name: "year", label: "工资月份", hidden: false },
        { name: "company_name", label: "用工单位", hidden: false },
        { name: "created_at", label: "生成时间",  width:"160", hidden: false},
        { name: "total_employees", label: "在职总数/人", hidden: false},
        // { name: "extra_invoice", label: "福利费/元", hidden: false},
        { name: "total_invoice", label: "开票金额/元", hidden: false},
        { name: "total_money", label: "应发工资/元", hidden: false},
        { name: "total_salary", label: "实发工资/元", hidden: false},
        { name: "pay_status", label: "状态", hidden: false},
        { name: "gzb", label: "工资表", hidden: false},
        { name: "action", label: "操作",width: "160", hidden: false }
      ],
      // 2为待确认显示的字段
      field2: [
        { name: "id", label: "ID", width:"80"},
        { name: "year", label: "工资月份", hidden: false },
        { name: "company_name", label: "用工单位", hidden: false },
        { name: "invoice_company_id", label: "开票单位", width:"200", hidden: false },
        { name: "created_at", label: "生成时间",  width:"160", hidden: false},
        { name: "total_employees", label: "在职总数/人", hidden: false},
        // { name: "extra_invoice", label: "福利费/元", hidden: false},
        { name: "total_invoice", label: "开票金额/元", hidden: false},
        { name: "total_money", label: "应发工资/元", hidden: false},
        { name: "total_salary", label: "实发工资/元", hidden: false},
        { name: "check_at", label: "确认时间", width: "160", hidden: false},
        { name: "pay_status", label: "状态", hidden: false},
        { name: "gzb", label: "工资表", hidden: false},
        { name: "action", label: "操作",width: "160", hidden: false }
      ],
      field3: [
        { name: "id", label: "ID", width:"80"},
        { name: "year", label: "工资月份", hidden: false },
        { name: "company_name", label: "用工单位", hidden: false },
        { name: "invoice_company_id", label: "开票单位", width:"200", hidden: false },
        { name: "created_at", label: "生成时间",  width:"160", hidden: false},
        { name: "total_employees", label: "在职总数/人", hidden: false},
        // { name: "extra_invoice", label: "福利费/元", hidden: false},
        { name: "total_invoice", label: "开票金额/元", hidden: false},
        { name: "total_money", label: "应发工资/元", hidden: false},
        { name: "total_salary", label: "实发工资/元", hidden: false},
        { name: "confirm_at", label: "定稿时间", width: "160", hidden: false},
        { name: "pay_status", label: "状态", hidden: false},
        { name: "gzb", label: "工资表", hidden: false},
        { name: "action", label: "操作",width: "220", hidden: false }
      ],
      field4: [
        { name: "id", label: "ID", width:"80"},
        { name: "year", label: "工资月份", hidden: false },
        { name: "company_name", label: "用工单位", hidden: false },
        { name: "invoice_company_id", label: "开票单位", width:"200", hidden: false },
        { name: "created_at", label: "生成时间",  width:"160", hidden: false},
        { name: "total_employees", label: "在职总数/人", hidden: false},
        // { name: "extra_invoice", label: "福利费/元", hidden: false},
        { name: "total_invoice", label: "开票金额/元", hidden: false},
        { name: "total_money", label: "应发工资/元", hidden: false},
        { name: "total_salary", label: "实发工资/元", hidden: false},
        { name: "issue_at", label: "发放时间", width: "160", hidden: false},
        { name: "pay_status", label: "状态", hidden: false},
        { name: "gzb", label: "工资表", hidden: false},
        { name: "action", label: "操作",width: "160", hidden: false }
      ],
      monthObj:['01','02','03','04','05','06','07','08','09','10','11','12'],
      table: {
        loading: false,
        params: {
          company_name: '',
          status: 1,
          pay_status: '',
          year:'',
          month: '',
          create_year: '', // 生成年
          create_month: '', // 生成月
          invoice_company_id:'', // 开票单位
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      rowId: '',
      isDetail: false,
      selPayStatus: [
        {
          id: 2,
          name :'已回款已发放'
        },
        {
          id: 3,
          name :'未回款已垫付'
        }
      ],
      payStatus: [
        {
          id: 0,
          name :'待定稿'
        },
        {
          id: 1,
          name :'待发放 '
        },
        {
          id: 2,
          name :'已回款已发放'
        },
        {
          id: 3,
          name :'未回款已垫付'
        }
      ],
      dialogVisible: false,
      curType: '',
      curRow: {},
      batchData: [],
      statusTotal: {
        to_review_count: '',
        to_confirm_count: '',
        stay_out_count: '',
        issued_count: '',
      },
      curField: [],
      dialogInfo: {
         radioStatus: true,
         id: ''
      },
      icId: Number(getStorage('icId')), // 登录账号的身份id（0：总公司, 2:鹰潭分公司）
    }
  },
  created() {
    this.curField = this.field
    // 默认当前年月
    this.table.params.create_year = this.$moment().format("yyyy");
    this.table.params.create_month = this.monthObj[this.$moment().format("MM") - 1]
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.post('/admin/finance/list', this.table.params).then(res => {
        if(res.code === 1) {
          this.statusTotal.to_review_count = res.data.to_review_count
          this.statusTotal.to_confirm_count = res.data.to_confirm_count
          this.statusTotal.stay_out_count = res.data.stay_out_count
          this.statusTotal.issued_count = res.data.issued_count
  
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 新增工资表
    handleAdd() {
      let dom = this.$refs.add
      dom.toggle(true)
      dom = null
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 返回 type:1-无卡， 2-退回
    handleUntreadList(row, type) {
      let dom = this.$refs.UntreadList
      dom.toggle(true)
      dom.getDetail(row, type)
      dom = null
    },
    // 批量选择的数据
    handleSelectionChange(batchData) {
      this.batchData = batchData;
    },
    // 批量发放
    handleBatch() {
      if(this.batchData.length > 0) {
        this.curType = 3
        let dom = this.$refs.confirm
        dom.toggle(true)
        // dom.getDetail(this.batchData)
        dom.getBatchData(this.batchData)
        dom = null
      } else {
        this.$message.warning('请选择需要批量发放的数据！')
      }
    },
    // 驳回、定稿、发放
    handleConfirm(type,row) {
      // 0:驳回、1:定稿
      this.curType = type;
      this.curRow = row
      let dom = this.$refs.confirm
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 导出
    handleExport(row) {
      exportData({id: row.id},'/admin/finance/export')
    },
    handleTabs(value) {
      if(value === 1) {
        this.curField = this.field
      } else if(value === 3) {
        this.curField = this.field2
      } else if(value === 4) {
        this.curField = this.field3
      } else if(value === 5) {
        this.curField = this.field4
      }
      this.table.params.page = 1;
      this.table.data = [];
      this.getTable();
    },
    handleStatus(row) {
      this.dialogVisible = true;
      this.dialogInfo.id = row.id
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // 已发放列表下的状态为“未回款已垫付”时可以更改为“已回款已发放”
    statusCommit() {
      let _params = {
        pay_status: 2,
        id: this.dialogInfo.id
      }
      this.$http.post('/admin/finance/pay', _params).then(res => {
        if(res.code ===1) {
          this.dialogVisible = false
          this.table.params.status = 5 // 刷新状态5：已发放的数据表格
          this.getTable()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 预览
    handlePreview(row) {
      let dom = this.$refs.preview
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 退回
    handleRetreat(row) {
      this.$confirm('确认退回吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/admin/finance/rollback', {id: row.id}).then(res => {
          if(res.code === 1) {
            this.$message.success('操作成功！')
            this.getTable();
          } else {
            this.$message.error(res.msg)
          }
        }).catch(() => {})
      }).catch(() => {});
    },
    selMonth(v) {
      this.table.params.create_month = v;
      this.getTable();
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-wrap {
  text-align: center;
  p {
    margin: 20 auto;
  }
  .company-name {
    margin-bottom: 20px;
  }
}
.el-date-editor.el-input {
  width: 220px !important;
}
.el-form--inline .el-form-item {
  margin-bottom: 18px !important;
}
.monthV {
  margin-left: 16px;
  cursor: pointer;
  font-weight: bold;
  color: #595959;
}
.acColor {
  color: #409eff;
}
</style>