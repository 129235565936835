<template>
  <el-dialog
    append-to-body
    width="500px"
    title=""
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="dialog-wrap">
      <el-form
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='110px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <template v-if="this.curType === 0 || this.curType === 1">
          <p class="company-name">{{detailInfo.company_name}}</p>
          <p>在职人数：{{detailInfo.total_employees}}人</p>
          <p>应发工资：{{detailInfo.total_money}}元</p>
          <p v-if="this.curType === 1">实发工资：{{detailInfo.total_salary}}元</p>
        </template>
        <template v-if="this.curType === 2">
          <p>发放单位:{{detailInfo.company_name}}</p>
          <el-radio-group v-model="pay_status" size="small">
            <el-radio :label="2" border>已回款已发放</el-radio>
            <el-radio :label="3" border>未回款已垫付</el-radio>
          </el-radio-group>
        </template>
        <!-- 批量发放 -->
        <template v-if="this.curType === 3">
          <el-form-item label="发放单位：">
            <p style="text-align: left;margin: 0;" v-for="v in this.batchData" :key="v.id">{{v.company_name}}</p>
          </el-form-item>
          <el-radio-group v-model="pay_status" size="small">
            <el-radio :label="2" border>已回款已发放</el-radio>
            <el-radio :label="3" border>未回款已垫付</el-radio>
          </el-radio-group>
        </template>
      </el-form>
    </div>
    <el-row type="flex" justify="center" style="margin-top:50px">
      <template v-if="this.curType === 0 || this.curType === 1">
        <el-button type="primary" @click="handleSubmit">{{this.curType === 0 ? '确认驳回' : '确认定稿'}}</el-button>
      </template>
      <!-- curType: 2发放 3.批量发放 -->
      <template v-if="this.curType === 2 || this.curType === 3">
        <el-button type="primary" @click="handleSubmit1">确认</el-button>
      </template>
    </el-row>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'SystemUnitEdit',
    props: ['curType','curRow'],
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            company: {},
            type: '',
            time: '',
          },
        },
        pay_status: '',
        detailInfo: {},
        batchData: [], // 批量发放的数据
       
      }
    },
    watch:{
      curRow(val) {
        common.deepClone(this.detailInfo, val)
      }
    },
    methods: {
      // 获取组织详情
      getDetail(row) {
        this.isChange = true
        this.form.data = row;
      },
      // 获取批量发放的数据
      getBatchData(data) {
        this.batchData = data;
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 驳回、定稿
      handleSubmit() {
        let apiUrl = this.curType === 0 ? '/admin/finance/rejected' : '/admin/finance/confirm'
        this.$confirm("确认操作？", "提示", {
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              this.$http.post(apiUrl, {id: this.detailInfo.id}).then(res => {
                if(res.code === 1) {
                  this.toggle(false);
                  this.$emit('refresh')
                } else {
                  this.$message.error(res.msg)
                }
              }).catch(err => {
                this.$message.error(err.msg)
              })
              done()
            } else {
              done()
            }
          }
        })
      },
      // 发放
      handleSubmit1() {
        this.$confirm("确认操作？", "提示", {
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              let _params = {pay_status: this.pay_status}
              if(this.curType === 2) {
                _params.id = this.detailInfo.id
              } else if(this.curType === 3) {
                let _ids = []
                this.batchData.forEach(v => {
                  _ids.push(v.id)
                })
                _params.ids = _ids.toString()
              }
              this.$http.post('/admin/finance/pay', _params).then(res => {
                if(res.code ===1) {
                  this.toggle(false);
                  this.$emit('refresh')
                } else {
                  this.$message.error(res.msg)
                }
              })
              done()
            } else {
              done()
            }
          }
        })
      },
    }
  }
</script>

<style scoped lang="scss">
.dialog-wrap {
  text-align: center;
  p {
    margin: 20 auto;
  }
  .company-name {
    margin-bottom: 20px;
  }
}
</style>
