<template>
  <div class="addPerson__wrap" v-show="visible">
    <el-button class="backBtn" plain icon="el-icon-d-arrow-left" @click="toggle(false)">返回</el-button>
    <div style="margin:10px 18px;font-weight:bold">
      {{this.company_name}}
    </div>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="员工姓名" prop="name">
          <el-input v-model="table.params.name" clearable placeholder="请输入员工姓名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- 状态：3 待定稿 4待发放 5 已发放 -->
      <div class="btnAdd" style="margin-top:10px;">
        <el-button :size="subUnitSize" type="primary" @click="handleSendBatch">批量退回</el-button>
      </div>
    </div>
    <div class="main">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="field"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showSelect="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSelectionChange="handleSelectionChange"
      >
        <template v-slot:year="{row}">
          <span>{{row.year}}年{{row.month}}月</span>
        </template>
        <template v-slot:action="{row}">
          <el-button type="text" @click="handleUntread(row)">退回</el-button>
        </template>
      </VTable>
    </div>

    <el-dialog
      title="退回"
      :visible.sync="form.dialogVisible"
      width="30%"
      :before-close="handleClose"
      append-to-body>

      <el-form ref="elFormDom" :model="form.data" :rules='form.rules' label-position="right"  label-width="100px">
        <el-form-item label="员工姓名：">
          <span v-for="v in form.sendStaff" :key="v">{{v}}&nbsp;&nbsp;</span>
        </el-form-item>
        <el-form-item label="退回原因：" prop="reason">
          <el-select v-model="form.data.reason" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="form.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import VTable from '@/components/VTable';
  import { mixinTable } from '@/mixins/table.js'
  export default {
    name: 'untreadList',
    mixins:[mixinTable],
    components: {
      VTable
    },
    data() {
      return {
        visible: false,
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        field: [
          { name: "id", label: "ID", width:"100"},
          { name: "employee_name", label: "姓名", hidden: false },
          { name: "id_card_no", label: "身份证", hidden: false},
          { name: "bank_card_no", label: "银行卡号", hidden: false},
          { name: "bank_name", label: "开户行", hidden: false},
          { name: "real_salary", label: "实发金额(元)", hidden: false},
          { name: "entry_at", label: "入职时间", hidden: false},
          { name: "year", label: "月份", hidden: false},
          { name: "action", label: "操作",width: "100", hidden: false }
        ],
        table: {
          loading: false,
          params: {
            name: '',
            settlement_id: '',
            page: 1,
            count: 10
          },
          data: [],
          total: 0,
        },
        company_name: '',
        form: {
          dialogVisible: false,
          sendStaff: [],
          data: {
            id: '',
            reason: '',
            name: '',
            pay_status: ''
          },
          rules:{
            reason: [{ required: true, message:'请选择退回原因', trigger: 'change'}],
          }
        },
        options: [
          {
            value: 2,
            label: '银行卡信息有误'
          },
          {
            value: 1,
            label: '银行卡冻结'
          },
          {
            value: 3,
            label: '其他'
          }
        ],
        curType: '',
      }
    },
    
    methods: {
      getDetail(row, type){
        this.curType = type; // 无卡(1)或退回(2)
        this.company_name = row.company_name
        this.table.params.settlement_id = row.id
        this.getTable();
      },
      getTable() {
        this.$http.get('/admin/reissue/applyList', {params:this.table.params}).then(res => {
          if(res.code === 1) {
            this.table.data = res.data.list;
            this.table.total = res.data.total;
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.table.params.page = 1;
        this.table.params.count = 10;
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // pageSize 改变时会触发
      handleSizeChange(pageSize) {
        this.table.params.count = pageSize;
        this.table.params.page = 1;
        this.getTable();
      },
      handleCurrentChange(page) {
        this.table.params.page = page;
        this.getTable();
      },
      // 批量选择的数据
      handleSelectionChange(batchData) {
        let ids = []
        this.form.sendStaff = [];
        if(batchData.length > 0) {
          batchData.forEach(v => {
            ids.push(v.id)
            this.form.sendStaff.push(v.employee_name)
          })
        }
        this.form.data.id = ids.toString()
      },
      // 批量退回
      handleSendBatch(batchData) {
        if(this.form.data.id.length > 0) {
          this.form.dialogVisible = true;
        } else  {
          this.$message.warning("请选择要批量退回的数据！");
        }
      },
      // 退回
      handleUntread(row) {
        this.form.sendStaff = []
        this.form.dialogVisible = true;
        this.form.sendStaff.push(row.employee_name)
        this.form.data.id = row.id
      },
      confirm() {
        // 补发状态：1-无卡；2-退回；5-暂不发放；6-不予发放
        this.form.data.pay_status = this.curType;
        this.$http.post('/admin/reissue/backpay', this.form.data).then(res => {
          if(res.code === 1) {
            this.$message.success('操作成功！')
            this.form.dialogVisible = false;
            this.getTable();
            this.form.data.id = ''
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      handleClose() {
        this.form.dialogVisible = false;
      }
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #fff;
  margin: 10px;
  z-index: 5;
  overflow: scroll;
  
  .main {
    margin: 0 auto;

    .staff-from {
      width: 80%;
      margin: 0 auto;
    }
  }
}

</style>
